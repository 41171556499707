import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import FieldsModal from '../common/FieldsModal';
import CreateRoute from './NewRouteModal';
import FinancialReportModal from './FinancialReportModal';
import SendMessageModal from './SendMessageModal';
import { ROUTE_FIELDS } from '../../services/helpers';
import { updateChangeRowsPerPage } from '../../helpers';

const fixDateFormat = (date) => {
  if (!date) return '';
  const localDate = new Date(date);
  return localDate.toISOString().split('T')[0];
};
const Routes = ({
  permission, routeManifests, defaultLimit, allRecordsCount,
  states, currentUserId, currentUserType, userTypes, type, preferences, paginationKey,
}) => {
  const getFields = () => {
    const defaultArray = [
      { header: 'Actions', key: 'actions', format: true },
      { header: 'Dispatcher', key: 'dispatcher', format: true },
      { header: 'Date', key: 'date', dateFilter: true },
      { header: 'Id', key: 'id' },
      {
        header: 'Level', key: 'route_manifest_type', filtering: true, filterKey: 'level',
      },
      { header: 'Stops', key: 'pickup_count_for_user_role' },
      { header: 'Packages', key: 'packages_count_for_user_role' },
      { header: 'Status', key: 'status', filtering: true },
      {
        header: 'Package Cluster', key: 'package_cluster_id', format: true, filtering: true,
      },
      { header: 'State', key: 'state', format: true },
      {
        header: 'Driver', key: 'driver', format: true, filtering: true,
      },
      { header: 'Approved', key: 'approved' },
      { header: 'Mileage (mi)', key: 'mileage' },
      { header: 'Estimated cost', key: 'estimated_cost' },
      { header: 'Total time', key: 'route_time' },
    ];
    return defaultArray;
  };
  const assembleFilter = () => {
    const obj = {};
    getFields().filter(el => el.filtering || el.dateFilter).forEach((field) => {
      const key = field.filterKey || field.key;
      if (urlSearchParams[key]) obj[key] = urlSearchParams[key];
    });
    return obj;
  };
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(paginationKey), 10) || preferences?.records_per_page || 10,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [filter, setFilter] = useState(assembleFilter());
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [state, setState] = useState(urlSearchParams.state || '');
  const [selectedStatus, setSelectedStatus] = useState(urlSearchParams.filter_status || '');
  const [routeByDay, setRouteByDay] = useState(fixDateFormat(urlSearchParams.generate_filter_date));
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showFinancialModal, setShowFinancialModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [reportCompanies, setReportCompanies] = useState([]);
  const [showReportCompaniesList, setShowReportCompaniesList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRows(routeManifests);
    setPaginationOptions({
      ...paginationOptions,
      count: allRecordsCount,
      limit: defaultLimit,
    });
  }, [routeManifests, allRecordsCount, defaultLimit]);

  useEffect(() => {
    handleFilter({});
  }, [filter]);

  const fetchReportCompanies = (id) => {
    api.routesDashboard.fetchRouteCompanies(id).then((res) => {
      setReportCompanies(res.companies);
    })
      .catch(err => console.log(err));
  };

  const handleReportDownload = (routeId, companyId) => {
    setLoading(true);
    api.routesDashboard.generateReport(routeId, companyId).then((res) => {
      window.open(res.url, '_blank');
    })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const updatePageAddress = (data) => {
    const routeTypeSegment = type.toLowerCase() === 'rl2' ? '/rl2_routes' : '';
    const url = `/admin/route_manifests${routeTypeSegment}?${qs.stringify(data)}`;
    window.history.replaceState(null, '', url);
  };

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
      filter_status: selectedStatus,
      state,
      generate_filter_date: routeByDay,
      ...filter,
    };
    api.routesDashboard[`${type.toLowerCase()}_routes`](filters)
      .then((res) => {
        setRows(res.records);
        setPaginationOptions({
          count: res.all_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        updatePageAddress(filters);
      })
      .catch(err => console.log(err));
  }, [paginationOptions, state, selectedStatus, routeByDay, filter]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };
  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, paginationKey, val => handleFilter({ page: 0, limit: val }));
  };

  const handleReset = () => {
    setRouteByDay('');
    setSelectedStatus('');
    setState('');
    setFilter({});
    updatePageAddress({
      page: 1,
      limit: paginationOptions.limit,
    });
  };

  const handleDelete = useCallback(() => {
    api.routesDashboard.deleteRoute(selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleApprove = useCallback(() => {
    api.routesDashboard.approveRoute(selectedRow.id, { route_manifest: { approved: !selectedRow.approved } }).then(() => {
      setOpenApprove(false);
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleCopy = useCallback(() => {
    api.routesDashboard.copyRoute(selectedRow.id).then(() => {
      setOpenCopy(false);
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(ROUTE_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(ROUTE_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">{`${type} Routes`}</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                {permission.routeManifests?.add && (
                  <button onClick={() => setShowCreateModal(true)} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add New Route Manifest</button>
                )}
                {currentUserType === userTypes.OWNER_TYPE && (
                  <button className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }} onClick={() => setShowFinancialModal(true)}>Create financial report</button>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <select
                    name="filter_status"
                    id="filter_status"
                    className="form-control"
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                    }}
                  >
                    <option value="">Select status</option>
                    <option value="unassigned">Unassigned</option>
                    <option value="assigned">Assigned</option>
                    <option value="rejected">Rejected</option>
                    <option value="accepted">Accepted</option>
                  </select>
                  <select
                    name="state"
                    id="state"
                    className="form-control"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  >
                    <option value="">All States</option>
                    {states.map(s => (
                      <option value={s} key={s}>{s}</option>
                    ))}
                  </select>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="route_date"
                      name="route_date"
                      value={routeByDay}
                      onChange={e => setRouteByDay(e.target.value)}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <button style={{ margin: '0 5px' }} className="btn-primary btn" onClick={() => handleFilter({})}>Submit</button>
                  <button className="btn-danger btn pickups_list_filter_reset" onClick={handleReset}>Reset</button>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              {loading && (
                <div className="loading">
                  <div className="loader" />
                </div>
              )}
              <CustomTable
                rows={rows}
                setFilter={setFilter}
                filter={filter}
                formatters={{
                  actions: value => (
                    <div className="text-center" style={{ whiteSpace: 'nowrap' }}>
                      <a
                        className="fa fa-eye show-package-pickup-list"
                        onClick={() => {
                          setSelectedRow(value);
                        }}
                        href={`/admin/route_manifests/${value.id}`}
                      />
                      <a
                        className="fa fa-envelope space-left-right"
                        onClick={() => {
                          setSelectedRow(value);
                          setShowMessageModal(true);
                        }}
                        data-remote="true"
                        href={`/admin/route_manifests/send_message_modal?id=${value.id}`}
                      />
                      {(currentUserType === userTypes.OWNER_TYPE || currentUserType === userTypes.SUPER_ADMIN_TYPE) && (
                        <a
                          className="fa fa-comments space-left-right"
                          style={{ width: '18px' }}
                          onClick={() => {
                            setSelectedRow(value);
                          }}
                          href={`/admin/twilio_chat/open_chat?manifest_id=${value.id}`}
                        />
                      )}
                      {permission.DriverLocationTrack?.view && (
                        <a
                          className="fa fa-solid fa-map-pin"
                          onClick={() => {
                            setSelectedRow(value);
                          }}
                          href={`/admin/route_manifests/driver_location_track?manifest_id=${value.id}`}
                        />
                      )}
                      {permission.routeManifests?.edit && (
                        <>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <a
                            className="fa fa-trash space-left-right"
                            onClick={() => {
                              setOpenDelete(true);
                              setSelectedRow(value);
                            }}
                          />
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <a
                            className={`fa ${value.approved ? 'fa-thumbs-o-down' : 'fa-thumbs-o-up'}`}
                            onClick={() => {
                              setOpenApprove(true);
                              setSelectedRow(value);
                            }}
                          />
                        </>
                      )}
                      <span className="dropdown route-report-dropdown">
                        {currentUserType === userTypes.SALES_ADMIN_TYPE && (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                          <a className="fa fa-file-pdf-o pdf-icon" title="Route Report" onClick={() => handleReportDownload(value.id, null)} />
                        )}
                        {currentUserId !== userTypes.SALES_ADMIN_TYPE && (
                          <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <a
                              className="fa fa-file-pdf-o pdf-icon"
                              onClick={() => {
                                if (showReportCompaniesList) {
                                  setSelectedRow({});
                                  setReportCompanies([]);
                                } else {
                                  fetchReportCompanies(value.id);
                                  setSelectedRow(value);
                                }
                                setShowReportCompaniesList(!showReportCompaniesList);
                              }}
                              data-id={value.id}
                            />
                            <ul className={`dropdown-menu ${selectedRow?.id === value.id && showReportCompaniesList ? 'show' : ''}`} role="menu">
                              {selectedRow?.id === value.id && (
                                <>
                                  {reportCompanies.map(el => (
                                    <li>
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                      <a onClick={() => handleReportDownload(value.id, el.id)} className="dropdown-item">{el.name}</a>
                                    </li>
                                  ))
                                  }
                                  <li>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <a onClick={() => handleReportDownload(value.id)} className="dropdown-item">Select all</a>
                                  </li>
                                </>
                              )}
                            </ul>
                          </>
                        )}
                      </span>
                      {permission.routeManifests?.add && (
                        <a
                          className="fa fa-clone"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedRow(value);
                            setOpenCopy(true);
                          }}
                          href={`/admin/route_manifests/copy_single_route?id=${value.id}`}
                        />
                      )}
                    </div>
                  ),
                  dispatcher: value => (
                    <span>{value.package_cluster?.airport?.dispatcher || ''}</span>
                  ),
                  package_cluster_id: value => (
                    <span>{value.package_cluster ? `${value.package_cluster.region_id_lvl1} @ ${value.package_cluster.airport_code}` : ''}</span>
                  ),
                  driver: value => (
                    <span>{value.driver?.user ? `${value.driver.user.first_name || ''} ${value.driver.user.last_name || ''}` : ''}</span>
                  ),
                  approved: value => (
                    <>
                      {value && (
                        <i className="fa fa-check" />

                      )}
                      {!value && (
                        <i className="fa fa-times" />
                      )}
                    </>
                  ),
                  mileage: value => (value ? value.split('mi')[0] : ''),
                  state: value => (value.package_cluster?.airport?.state ? value.package_cluster.airport.state : ''),
                }}
                rowsProps={selectedFields}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                rowStyle={(row) => {
                  const status = get(row, 'status');
                  if (status === 'accepted') return ({ backgroundColor: '#90ee90' });
                  if (status === 'rejected') return ({ backgroundColor: '#ff000070' });
                  return ({});
                }}
                suffix="route_manifest"
              />
            </div>
            <ConfirmationDialog
              onConfirm={handleDelete}
              onClose={() => setOpenDelete(false)}
              message="Are you sure you want to delete this route?"
              open={openDelete}
            />
            <ConfirmationDialog
              onConfirm={handleApprove}
              onClose={() => setOpenApprove(false)}
              message={`Are you sure you want to ${selectedRow.approved ? 'reject' : 'approve'} this route manifest?`}
              open={openApprove}
            />
            <ConfirmationDialog
              onConfirm={handleCopy}
              onClose={() => setOpenCopy(false)}
              message={`Route ${selectedRow.id} will be copied to current day`}
              open={openCopy}
            />
            {
              showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
            }
            {
              showCreateModal && (
                <CreateRoute
                  handleClose={() => setShowCreateModal(false)}
                  open={showCreateModal}
                  type={type}
                  onSubmit={() => {
                    handleFilter({});
                    setShowCreateModal(false);
                  }}
                />
              )
            }
            {
              showFinancialModal && (
                <FinancialReportModal
                  handleClose={() => setShowFinancialModal(false)}
                  open={showFinancialModal}
                  selectedDate={routeByDay}
                />
              )
            }
            {
              showMessageModal && (
                <SendMessageModal
                  handleClose={() => setShowMessageModal(false)}
                  open={showMessageModal}
                  driver={selectedRow.driver}
                />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
Routes.propTypes = {
  routeManifests: PropTypes.instanceOf(Array).isRequired,
  currentUserType: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userTypes: PropTypes.instanceOf(Object).isRequired,
  allRecordsCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
  paginationKey: PropTypes.string.isRequired,
};
export default Routes;
