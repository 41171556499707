import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import api from '../../services/api';
import { printLabel, printZplLabels, splitArrayPerChunks } from '../../services/helpers';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const ConsolidationBags = ({
  newCLPath, labels, allLabelsCount, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.consolidationBags), 10) || preferences?.records_per_page || 10,
  });
  const [bulkAction, setBulkAction] = useState('');
  const [bagStatus, setBagStatus] = useState(urlSearchParams.bag_status || '');

  useEffect(() => {
    setRows(labels);
    setPaginationOptions({
      ...paginationOptions,
      count: allLabelsCount,
    });
  }, [labels, allLabelsCount]);

  useEffect(() => {
    handleFilter({});
  }, [bagStatus]);

  const handlePrintZPL = async (row) => {
    const res = await api.flightDashboard.bulkActions({
      bulk_action: 'print_zpl',
      ids: [row],
      controller_name: 'consolidation_labels',
    });

    printZplLabels(res);
  };

  const handleAction = useCallback(async () => {
    if (bulkAction === 'display_html') {
      let labelsToPrint = '';
      for await (const items of splitArrayPerChunks(selectedRows, 10)) {
        const res = await api.flightDashboard.bulkActions({
          bulk_action: bulkAction,
          ids: items.map(row => row.id),
          controller_name: 'consolidation_labels',
        });
        labelsToPrint += res.img_tags;
      }
      printLabel(labelsToPrint);
    } else if (bulkAction === 'fetch_labels') {
      api.flightDashboard.bulkActions({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'consolidation_labels',
      });
    } else if (bulkAction === 'print_zpl') {
      const res = await api.flightDashboard.bulkActions({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'consolidation_labels',
      });

      printZplLabels(res);
    } else {
      api.flightDashboard.bulkActionsF({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'consolidation_labels',
      });
    }
  }, [bulkAction, selectedRows]);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      bag_status: bagStatus,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.flightDashboard.labels(filters)
      .then((res) => {
        setRows(res?.labels);
        setPaginationOptions({
          count: res?.all_labels,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/consolidation_labels?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [bagStatus, paginationOptions]);

  const handleReset = () => {
    setBagStatus('');
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.consolidationBags, val => handleFilter({ page: 0, limit: val }));
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <div className="box-header">
            <h1 className="box-title">Consolidation Bags</h1>
            <div className="pull-right">
              <a href={newCLPath} className="btn bg-olive margin add-btn">Add Consolidation Label</a>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="status-sec">
              <div className="form-inline">
                <div className="form-group">
                  <select className="form-control" value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
                    <option value="">Select Action</option>
                    <option value="fetch_labels">Generate labels</option>
                    <option value="print_zpl">Print ZPL</option>
                    <option value="display_html">Print selected labels</option>
                    <option value="download_pdf">Download selected labels</option>
                  </select>
                </div>
                <button style={{ marginLeft: '10px' }} className="bulk_action_submit btn-primary btn" onClick={handleAction}>Submit</button>
              </div>
              <span className="bulk_action_error" />
            </div>
            <div className="status-sec">
              <div className="form-inline" style={{ display: 'flex' }}>
                <select
                  name="bag_status"
                  id="bag_status"
                  className="form-control"
                  value={bagStatus}
                  onChange={(e) => {
                    setBagStatus(e.target.value);
                  }}
                >
                  <option value="all">All Bags</option>
                  <option value="created">Created</option>
                  <option value="origin_airport">Origin Airport</option>
                  <option value="destination_airport">Destination Airport</option>
                </select>
                {/* <button className="btn-primary btn" onClick={handleFilter}>Submit</button> */}
                <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
              </div>
            </div>
          </div>
          <div className="box-body main_listing">
            <CustomTable
              rows={rows}
              formatters={{
                created_at: value => moment(value).format('DD MMM YYYY'),
                actions: value => <a href={`/admin/consolidation_labels/${value?.id}`} className="fa fa-eye edit_user" />,
                labels: value => (
                  <>
                    {value?.zpl_file_attached && <button onClick={() => handlePrintZPL(value?.id)} className="fa fa-print pdf-icon" style={{ border: 'none', backgroundColor: 'transparent' }} />}
                    {value?.pdf_file_attached && <a href={`/admin/consolidation_labels/${value?.id}/show_html`} className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" />}
                    {value?.html_file_attached && <a href={`/admin/consolidation_labels/${value?.id}/show_pdf`} className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" />}
                  </>
                ),
              }}
              rowsProps={[
                { header: 'Labels', key: 'labels', format: true },
                { header: 'Origin Airport', key: 'origin_airport.code' },
                { header: 'Destination Airport', key: 'destination_airport.code' },
                { header: 'Created At', key: 'created_at' },
                { header: 'Code', key: 'code' },
                { header: 'Status', key: 'status_text' },
                { header: 'Load Master', key: 'is_load_master' },
                { header: 'Company', key: 'company.name' },
                { header: 'Actions', key: 'actions', format: true },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              paginationOptions={{
                ...paginationOptions,
                handleChangePage,
                handleChangeRowsPerPage,
              }}
              suffix="shipments"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConsolidationBags.propTypes = {
  newCLPath: PropTypes.string.isRequired,
  allLabelsCount: PropTypes.number.isRequired,
  labels: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default ConsolidationBags;
